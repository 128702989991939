import {FedopsInteractions, SPECS} from '../constants';
import {installMembersArea, isMembersAreaInstalled} from '@wix/members-area-integration-kit';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomComponentsData, EcomPlatformInstallationState} from '../services/EcomPlatformInstallationState';
import {getEcomPagesConfiguration} from '../../common/appManifest/appManifest';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {StyleParams} from '../services/StyleParams';
import {ContextParams, EditorSDK, PageRef, PopupData, RouterRef} from '@wix/platform-editor-sdk';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';
import {ExperimentsApi} from '../../common/experiments/ExperimentsApi';
import {createWishlistApi, InstallationOrigin} from './wishlist/wishlistActions';
import {DependantApps} from '../services/DependantApps';
import {translateFunctionFactory} from '../../common/translations/translations';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {WIX_ECOM, WIX_NEW_STORES} from '@wix/app-definition-ids';
import {getEcomPages} from '../../common/pages';
import {AnyFunction} from '@wix/wixstores-client-core';
import {createLightbox} from './sideCartLightbox';
import {PageData} from '@wix/editor-platform-sdk-types';
import type {BehaviorObject} from '@wix/document-services-types';
import {IHttpClient} from '@wix/fe-essentials-editor/src/exports/http-client/index';

interface PageDataType {
  compId: string;
  pageRef: PageRef;
  pageUriSEO: string;
  title: string;
}

export async function addPage({
  sdk,
  pageId,
  managingAppDefId,
  shouldNavigate,
  fedopsLogger,
  reportError,
}: {
  sdk: EditorSDK;
  pageId: string;
  managingAppDefId: string;
  shouldNavigate: boolean;
  fedopsLogger: Record<string, any>;
  reportError: AnyFunction;
}) {
  managingAppDefId = pageId === PageMap.CHECKOUT ? WIX_ECOM : managingAppDefId;

  let pageData: PageDataType;
  try {
    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddPage);
    pageData = (await sdk.tpa.add.component('', {
      appDefinitionId: WIX_ECOM,
      managingAppDefId,
      componentType: sdk.document.tpa.TPAComponentType.Page,
      page: {
        pageId,
        shouldNavigate,
      },
    })) as PageDataType;

    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddPage);
  } catch (err) {
    fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddPage, err as Error);
    reportError(err, false);
  }

  if (!pageData) {
    return null;
  }

  try {
    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState);
    await sdk.document.pages.setState('', {
      state: {
        [pageId]: [{id: pageData.pageRef.id} as PageRef],
      },
    });
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState);
  } catch (err) {
    fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState, err as Error);
    reportError(err);
  }

  return pageData.pageRef;
}

export function addWidget(
  sdk: EditorSDK,
  widgetId: string,
  addToAllPages: boolean,
  fedopsLogger: Record<string, any>,
  reportError: AnyFunction
) {
  try {
    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddWidget);
    const compData = sdk.tpa.add.component('', {
      appDefinitionId: WIX_ECOM,
      componentType: sdk.tpa.TPAComponentType.Widget,
      widget: {
        widgetId,
        allPages: addToAllPages,
      },
    });
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddWidget);

    return compData;
  } catch (e) {
    fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddWidget, e as Error);
    reportError(e);
  }
}

export async function addLightbox({
  sdk,
  lightboxId,
  managingAppDefId,
  fedopsLogger,
  reportError,
  experiments,
  contextParams,
  t,
  httpClient,
}: {
  sdk: EditorSDK;
  lightboxId: string;
  managingAppDefId: string;
  fedopsLogger: Record<string, any>;
  reportError: AnyFunction;
  experiments: ExperimentsApi;
  contextParams: ContextParams;
  t: (key: string) => string;
  httpClient?: IHttpClient;
}) {
  try {
    let pageData: {pageRef: PageRef};
    if (lightboxId === PageMap.SIDE_CART) {
      if (
        !experiments.enabled(SPECS.ShouldInstallSideCart) &&
        !experiments.enabled(SPECS.UseMiniCartStylesInSideCart)
      ) {
        return;
      }

      const popupPages = await getPopups(sdk);
      if (popupPages?.some((p) => p.tpaPageId === lightboxId)) {
        if (experiments.enabled(SPECS.DisableSideCartAutoOpenOnOpenEditor)) {
          await disablePopupAutoOpen(sdk, PageMap.SIDE_CART);
        }
        return;
      }

      const isResponsive = contextParams.origin.type === 'RESPONSIVE';
      fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox);
      pageData = {pageRef: await addSideCart(sdk, managingAppDefId, isResponsive, t, experiments)};
      await disablePopupAutoOpen(sdk, PageMap.SIDE_CART);
      if (experiments.enabled(SPECS.UseMiniCartStylesInSideCart)) {
        const applicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
        const styleParamsHandler = new StyleParams(sdk, applicationId, httpClient);
        await styleParamsHandler.setSideCartStyleParams();
      }
    } else {
      fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox);
      reportError(Error(`Failed to add lightbox, lightbox id - ${lightboxId} not found`));
      return;
    }
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox);

    return pageData.pageRef;
  } catch (e) {
    fedopsLogger?.interactionFailed?.(FedopsInteractions.EcomPlatformPublicApiAddLightbox, e as Error);
    reportError(e);
  }
}

export function getRouterRefByPrefix(sdk: EditorSDK, prefix: string) {
  return sdk.routers.getByPrefix('', {prefix});
}

export async function addRouter(sdk: EditorSDK, prefix: string, reportError: AnyFunction) {
  try {
    const routerRef = await getRouterRefByPrefix(sdk, prefix);
    if (routerRef) {
      return routerRef;
    }

    return await sdk.routers.add('', {prefix});
  } catch (e) {
    reportError(e);
  }
}

export function getRouter(sdk: EditorSDK, routerRef: RouterRef) {
  return sdk.routers.get('', {routerRef});
}

export function connectPageToRouter(
  sdk: EditorSDK,
  {pageRef, routerRef, pageRoles}: {pageRef: PageRef; routerRef: RouterRef; pageRoles: string[]},
  reportError: AnyFunction
) {
  return sdk.routers.pages
    .connect('', {
      pageRef,
      routerRef,
      pageRoles,
    })
    .catch((e) => {
      reportError(e);
    });
}

export const toMonitored = async <T>(
  fedopsLogger: Record<string, any>,
  interactionName: string,
  action: () => Promise<T> | T
): Promise<T> => {
  try {
    fedopsLogger?.interactionStarted?.(interactionName);
    const response = await action();
    fedopsLogger?.interactionEnded?.(interactionName);
    return response;
  } catch (err) {
    fedopsLogger?.interactionFailed?.(interactionName, err as Error);
    throw err;
  }
};

export const addSideCart = async (
  sdk: EditorSDK,
  managingAppDefId: string,
  isResponsive: boolean = false,
  t: (key: string) => string,
  experiments: ExperimentsApi
) => {
  return createLightbox(sdk, managingAppDefId, isResponsive, t, experiments);
};

export async function addMembersArea(
  editorSDK: EditorSDK,
  editorType: string,
  firstInstall: boolean,
  biData?: {origin?: string},
  siteCreationFlow?: boolean,
  fedopsLogger?: Record<string, any>
) {
  if (!firstInstall || editorType === 'ADI' || siteCreationFlow) {
    return Promise.resolve();
  }

  const options = biData && {biData};
  await toMonitored(fedopsLogger, FedopsInteractions.MemberAreaInstallation, () =>
    installMembersArea(editorSDK, options)
  );
}

export async function setStateForStoresPages(sdk: EditorSDK) {
  const allSitePages = await sdk.pages.data.getAll('');
  const storesPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === WIX_NEW_STORES);
  return Promise.all(
    storesPages.map((pageData) => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.PRODUCT_VARIANCE ||
        pageData.tpaPageId === PageMap.CATEGORY ||
        pageData.tpaPageId?.startsWith(PageMap.GALLERY)
      ) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id} as PageRef],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export async function setStateForPages(sdk: EditorSDK) {
  const ecomPages = await getEcomPages(sdk);
  return Promise.all(
    ecomPages.map((pageData) => {
      if (pageData.tpaPageId === PageMap.CART || pageData.tpaPageId === PageMap.THANKYOU) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id} as PageRef],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export function openEcomBillingPage(editorSDK: EditorSDK) {
  return editorSDK.editor.openBillingPage('', {premiumIntent: 'PAID', referrer: 'stores_app_product'});
}

export async function installWishlistApp({
  sdk,
  dependantApps,
  t,
  webBiLogger,
}: {
  sdk: EditorSDK;
  dependantApps: DependantApps;
  t: (keyToTranslate: string) => string;
  webBiLogger: WebBiLogger;
}) {
  const wishlistApi = createWishlistApi({sdk, dependantApps, t, webBiLogger});
  if (await isMembersAreaInstalled(sdk)) {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: false});
  } else {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: true});
  }
}

export function updateEcomPlatformInstallationsData(
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  appDefId: string,
  ecomComponentsData: EcomComponentsData
) {
  ecomPlatformInstallationState.addInstallAppComponentsData(appDefId, ecomComponentsData);
}

export async function updateEcomPagesForPagesPanel(
  editorSDK: EditorSDK,
  ecomPlatformInstallationState: EcomPlatformInstallationState
) {
  const managingAppDefId = ecomPlatformInstallationState.getTheAppManageAppId();

  const tpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll('');
  const popupPages = await editorSDK.pages.popupPages.getApplicationPopups('', {includeUnmanaged: true});
  const ecomPopupPages = popupPages?.filter((page: PopupData) => page.tpaApplicationId === tpaApplicationId) || [];
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);

  return Promise.all([
    ...ecomPages.map(async (pageData: PageData) => {
      const {tpaPageId, id} = pageData;

      if (tpaPageId === PageMap.CART || tpaPageId === PageMap.THANKYOU) {
        await updateEcomPages(id, editorSDK, managingAppDefId);
      }

      return editorSDK.pages.setState('', {
        state: {
          [tpaPageId]: [{id} as PageRef],
        },
      });
    }),
    ...ecomPopupPages.map(async (pageData: PopupData) => {
      const {tpaPageId, id} = pageData;

      if (tpaPageId === PageMap.SIDE_CART) {
        await updateEcomPages(id, editorSDK, managingAppDefId);
      }
    }),
  ]);
}

export async function getEcomPlatformAppManifest(t, locale) {
  if (!t) {
    t = await translateFunctionFactory(locale);
  }
  const ecomPages = getEcomPagesConfiguration(t, getAppVersion(), locale);

  return {
    pages: {
      pageActions: {
        [PageMap.CART]: ecomPages[PageMap.CART].actions,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].actions,
      },
      pageSettings: {
        [PageMap.CART]: ecomPages[PageMap.CART].settings,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].settings,
      },
      pageDescriptors: {
        [PageMap.CART]: ecomPages[PageMap.CART].descriptors,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].descriptors,
      },
    },
  };
}

export async function setThankYouPageStyleParams({
  sdk,
  ecomPlatformInstallationState,
  styleParams,
}: {
  sdk: EditorSDK;
  ecomPlatformInstallationState: EcomPlatformInstallationState;
  styleParams?: EcomStyleParams;
  experimentsApi: ExperimentsApi;
}) {
  if (!ecomPlatformInstallationState.isMoreThanOneAppInstalled()) {
    const applicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
    const styleParamsHandler = new StyleParams(sdk, applicationId);
    return styleParamsHandler.setThankYouPageStyleParams(styleParams);
  }
}

export async function setSideCartStyleParams({
  sdk,
  httpClient,
}: {
  sdk: EditorSDK;
  experimentsApi: ExperimentsApi;
  httpClient: IHttpClient;
}) {
  const applicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
  const styleParamsHandler = new StyleParams(sdk, applicationId, httpClient);
  return styleParamsHandler.setSideCartStyleParams();
}

async function updateEcomPages(id: string, editorSDK: EditorSDK, managingAppDefId: string): Promise<void> {
  const pageRef: PageRef = {id, type: 'DESKTOP'};

  await editorSDK.pages.data.update('', {
    pageRef,
    data: {managingAppDefId},
  });
}

const getPopups = (editorSDK: EditorSDK) =>
  editorSDK.pages.popupPages.getApplicationPopups('', {
    includeUnmanaged: true,
  });

export const disablePopupAutoOpen = async (editorSDK: EditorSDK, pageToDisable: PageMap) => {
  const pagesData = await editorSDK.pages.data.getAll('_token');
  const allPageRefs = pagesData.map((page) => ({
    id: page.id,
    type: 'DESKTOP',
  })) as unknown as PageRef[];

  const installedPopups = (await getPopups(editorSDK))
    ?.filter((popup) => popup.tpaPageId === pageToDisable)
    .map((popup) => popup.id ?? '');

  for (const pageRef of allPageRefs) {
    const behaviors = (await editorSDK.components.behaviors.get('_token', {
      componentRef: pageRef,
    })) as BehaviorObject[];

    const autoOpenBehaviors = behaviors?.filter(
      (behavior) =>
        behavior.action.name === 'load' &&
        behavior.behavior.name === 'openPopup' &&
        installedPopups.includes(behavior.behavior.targetId)
    );

    for (const behavior of autoOpenBehaviors || []) {
      await editorSDK.components.behaviors.remove('_token', {
        componentRef: pageRef,
        // @ts-expect-error object type is valid here - Document Management also accepts it: https://github.com/wix-private/document-management/blob/340ef4a8c29ef22503fd3303fa4e60dd775c4362/document-services-implementation/src/actionsAndBehaviors/actionsAndBehaviors.ts#L334
        behaviorName: behavior,
      });
    }
  }
};
